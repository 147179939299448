@tailwind base;
@tailwind components;
@tailwind utilities;

.react-resizable-handle-n {
  top: -8px !important;
}

.react-resizable-handle-w {
  left: -8px !important;
}

.react-resizable-handle-nw {
  left: -8px !important;
  top: -8px !important;
}
